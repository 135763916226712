<template>
  <div>
    <el-dialog title="提示"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="700rem"
               :before-close="handleClose">
      <div class="text_warp"
           v-if="num == 0">
        您还有<span style="color:#FF6147">{{num}}</span>道题未作答，是否确认提交
      </div>
      <div class="text_warp"
           v-else>
        您还有<span style="color:#FF6147">{{num}}</span>道题未作答，建议继续答题
      </div>
      <div class="btn_warp"
           v-if="num">
        <div @click="save">
          确认提交
        </div>
        <div @click="handleClose">
          继续做题
        </div>
      </div>
      <div class="btn_warp"
           v-else>
        <div @click="handleClose">
          取消
        </div>
        <div @click="save">
          确认提交
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      num: 0
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    save () {
      this.$parent.submitPaper()
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23rem;
  font-size: 24rem;
  padding: 27rem;
  padding-top: 50rem;

  text-align: center;
  div {
    display: flex;
    justify-content: center;
  }
}
.btn_warp {
  height: 51rem;
  border-top: 1rem solid #e5e5e5;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70rem;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      font-size: 18rem;
      font-weight: bold;
      color: #999999;
      border-right: 1rem solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 18rem;
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0 !important;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}

::v-deep .dialog__title {
  font-size: 24rem;
}
::v-deep .el-dialog__header {
  font-size: 24rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
  padding-bottom: 0;
}
::v-deep .el-dialog {
  border-radius: 10rem;
  margin-top: 185rem !important;
  height: 277rem;
  position: relative;
}
</style>