import { instance } from '@/utils/http.js'

// 获取试卷科目模板
export const getStudentSubject = function (params) {
  return instance({
    url: '/api/v1/cp/cp_subject_tmp',
    method: 'get',
    params
  })
}

// 组卷并开始做卷
export const doPaper = function (data) {
  return instance({
    url: '/api/v1/cp/cp_action_two',
    method: 'post',
    data
  })
}

// 保存答案 
export const saveAnswer = function (data) {
  return instance({
    url: '/api/v1/my/save_answer',
    method: 'post',
    data
  })
}

// 上传试卷 
export const submitUserPaper = function (data) {
  return instance({
    url: '/api/v1/my/submit_answer',
    method: 'post',
    data
  })
}